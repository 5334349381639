@import "../../style/variables.scss";

.flash-container {
    // pointer-events: none;
    background: var(--warning);
    color: var(--lightgrey);
    position: fixed;
    
    padding: 0.3em 1em 0.3em 1em;
    text-align: center;
    // font-family: GINTOB;
    border: 2px solid var(--white);

    left: 50%;
    top: $lh * 1.7;
    transform: translate(-50%,0);
    filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.4));

    z-index: var(--ztop);
}

.flash-content {
    margin-right: 1.5em;
}
.flash-close {
    cursor: pointer;
    width: 1.6em;
    position: absolute;
    right: 0.2em;
    top: 0.2em;
}

.flash-close:hover {
    opacity: 0.8;
}