@import "../../style/variables.scss";

.slider {
    margin               : 0;
    padding              : 0;
    width                : 100vw;
    height               : 100vh;
    align-items          : center;
    // background: red;
    overscroll-behavior-x: none;
}

.slider-section {
    font-size: 5em;
    width    : 240vh;
    height   : 100vh;
    // border: solid 1px yellow;
    display  : inline-block;
    position : relative;
}

.slider-section-middle {
    z-index  : 100;
    font-size: 5em;
    width    : calc(240vh * 1.2);
    height   : 100vh;
    // border: solid 1px green;
    display  : inline-block;
    position : relative;
}

.slider-section-content {
    font-size: 5em;
    width    : calc(240vh * 1.4);
    height   : 100vh;
    // border: solid 1px magenta;
    display  : inline-block;
    position : relative;
}

.slider-section-top {
    font-size: 5em;
    width    : calc(240vh * 1.6);
    height   : 100vh;
    // border: solid 1px magenta;
    display  : inline-block;
    position : relative;
}

.slider-scroll-container {
    position      : absolute;
    top           : 0;
    left          : 0;
    display       : inline-flex;
    flex-direction: row;
    // background: green;
    will-change   : transform;
}

//---------------------
// Layers

.slider-scroll-container-top {
    z-index: var(--zslider3);
}

.slider-scroll-container-middle {
    // z-index: var(--zslider2);
    pointer-events: none;
}

.slider-scroll-container-bottom {
    z-index       : var(--zslider1);
    pointer-events: none;
}

.slider-livestream-container {
    cursor   : pointer;
    position : absolute;
    top      : 52%;
    left     : 50%;
    width    : 80vh;
    height   : auto;
    transform: translate(-50%-40%, -50%);
}

.slider-livestream-container:hover {
    filter: brightness(0.8);
}

.slider-player-container {
    position     : relative;
    top          : 1px;
    height       : 0;
    overflow     : hidden;
    position     : relative;
    background   : var(--white);
    padding-top  : 16px / 9px * 30%;
    border-radius: 1vh 1.5vh 10vh 2.5vh;
    border       : 2px white solid;
}

.slider-player {
    position: absolute;
    top     : 0;
    left    : 0;
    width   : 100%;
    height  : 100%;
}

.slider-audio-container {
    cursor: pointer;
    position : absolute;
    top      : 50%;
    left     : 50%;
    width    : 80vh;
    height   : auto;
    transform: translate(-50%-40%, -50%);
}

.slider-audio-container:hover {
    filter: brightness(0.8);
}

.slider-audioplayer-container {
    background       : url("../../public/images/scenes/audio.jpg");
    background-size: 100%;
    height: $lh*1.5;
    position     : relative;
    top          : 1px;
    border-radius: $lh/10 $lh/10 $lh/2 $lh/7;
    border       : 2px white solid;
}

// .slider-audioplayer {
//     position: absolute;
//     left: 50%;
//     top: 10%;
//     transform: translateX(-50%);
// }

// Description below preview
.slider-text-container {
    background       : url("../../public/images/masks/mask-livestream.svg");
    background-repeat: no-repeat;
    margin-left      : 3vh;
    margin-right     : 10vh;
    height: 11vh;

    padding-left     : 3vh;
    padding-right     : 4vh;
 
    font-size  : var(--big2);

    line-height: 1;
    font-family: "GINTON", "GINTO", "Helvetica Neue", arial, sans-serif;
    color      : var(--pc);

    overflow: hidden;

    filter: drop-shadow(0px 6px 5px rgba(0,0,0,0.3));
}

.slider-headline{
    padding-top: 0.8vh;
    text-transform: uppercase;
    font-size  : 3.5vh;
}

.slider-description{
    height: 5.4vh;
    line-height: 2.6vh;
    overflow: hidden;
    margin-top: 0.8vh;
    font-size  : 2.5vh;
}

// Icon play
.slider-play {
    z-index  : var(--zslider2);
    position : absolute;
    // left: 50%;
    left     : 50%;
    top      : 40%;
    width    : 16vh;
    transform: translate(-50%, -50%);
}

//--- Portals
.slider-portal {
    width: 30vh;
    font-size: 1.5vh;
    line-height: 1.2;
    text-align: center;
    cursor   : pointer;
    z-index  : var(--zslider3);
    padding-top: $lh/4;

    font-family: "GINTON", "GINTO", "Helvetica Neue", arial, sans-serif;

    border: 2px white solid;
    border-radius: 3vh;
    background: white;
    
    @include shadow(6px, 0.3);
}

.slider-portal:hover {
    filter: brightness(0.8);
}

.slider-portal-inactive {
    opacity       : 0.5;
    pointer-events: none;
}

.slider-portal-image{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    background: white;
    margin-bottom: $lh/2;
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 93%;
    border: 2px white solid;
    border-radius: 100%;

    filter: drop-shadow(7px 6px 5px rgba(0,0,0,0.3));
    z-index: var(--zslider4);
}

.slider-portal-background{
    border-radius: 0 0 3vh 3vh;
    position: absolute;
    bottom: 0;
    left: 0;
    margin-top: $lh*8;
    width: 100%;
}

.slider-portal-text{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 31vh;
    width: 80%;
    z-index: var(--zslider4);
}

.slider-portal1 {
    position : absolute;
    left     : 10%;
    top      : 40%;
    transform: translate(-50%-40, -50%);
    height: 39.5vh;
}

.slider-portal2 {
    position : absolute;
    left     : 22%;
    top      : 60%;
    transform: translate(-50%-40, -50%);
    height: 36.5vh;
}

.slider-portal3 {
    position : absolute;
    left     : 38%;
    top      : 50%;
    transform: translate(-50%-40, -50%);
    height: 35vh;
}

.slider-portal4 {
    position : absolute;
    left     : 53%;
    top      : 55%;
    transform: translate(-50%-40, -50%);
    height: 35vh;
}

.slider-portal5 {
    position : absolute;
    left     : 68%;
    top      : 40%;
    transform: translate(-50%-40, -50%);
    height: 39.5vh;
}

.slider-portal6 {
    position : absolute;
    left     : 80%;
    top      : 60%;
    transform: translate(-50%-40, -50%);
    height: 38vh;
}

.slider-portal7 {
    position : absolute;
    left     : 93%;
    top      : 50%;
    transform: translate(-50%-40, -50%);
    height: 36.5vh;
}

//----- video on demand

.slider-videoondemand{
    width: 37vh;
    height: 18vh;
    font-size: var(--normal2);
    line-height: 1;
    text-align: center;
    cursor   : pointer;
    z-index  : var(--zslider3);
    padding-top: $lh/4;

    font-family: "GINTON", "GINTO", "Helvetica Neue", arial, sans-serif;

    border: 2px white solid;
    border-radius: 3vh;
    background: white;
    
    @include shadow(6px, 0.3)
}

.slider-videoondemand:hover {
    filter: brightness(0.8);
}

.slider-videoondemand-inactive{
    filter: brightness(0.7);
    pointer-events: none;
}

.slider-videoondemand-image{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;

    display: block;

    border-radius: 0 0 3vh 3vh;
    z-index: var(--zslider3);
}

.slider-videoondemand-text{
    position: absolute;
    width: 100%;
    left: 50%;
    bottom: 2vh;
    transform: translateX(-50%);

    font-size: 3vh;
    line-height: 3.3vh;
    font-family: "GINTON", "GINTO", "Helvetica Neue", arial, sans-serif;
    z-index: var(--zslider4);
}
.slider-videoondemand-text-2{
    font-size: 3vh;
}

.slider-videoondemand1{
    position : absolute;
    left     : 20%;
    top      : 50%;
    transform: translate(-50%-40, -50%);
}

.slider-videoondemand2{
    position : absolute;
    left     : 40%;
    top      : 40%;
    transform: translate(-50%-40, -50%);
}

.slider-videoondemand3{
    position : absolute;
    left     : 60%;
    top      : 60%;
    transform: translate(-50%-40, -50%);
}

.slider-videoondemand4{
    position : absolute;
    left     : 80%;
    top      : 50%;
    transform: translate(-50%-40, -50%);
}

//-- landing buttons

.slider-landing{
    width: 30vh;
    height: 15vh;
    font-size: var(--normal2);
    line-height: 1;
    text-align: center;
    cursor   : pointer;
    z-index  : var(--zslider3);
    padding-top: $lh/4;

    font-family: "GINTON", "GINTO", "Helvetica Neue", arial, sans-serif;

    border: 2px white solid;
    border-radius: 3vh;
    
    @include shadow(6px, 0.3)
}

.slider-landing:hover {
    filter: brightness(0.8);
}

.slider-landing-text{
    position: absolute;
    width: 90%;
    left: 50%;
    bottom: 3vh;
    transform: translateX(-50%);

    font-size: 2vh;
    line-height: 1.3;
    font-family: "GINTON", "GINTO", "Helvetica Neue", arial, sans-serif;
    z-index: var(--zslider4);
}

.slider-landing1{
    background: var(--tc);
    position : absolute;
    left     : calc(160vh + 5vw);
    top      : 33%;
    transform: translate(-50%-40, -50%);
}

.slider-landing2{
    background: var(--pc);
    position : absolute;
    left     : calc(160vh - 35vw);
    top      : 52%;
    transform: translate(-50%-40, -50%);
}

.slider-landing3{
    background: var(--tc);
    position : absolute;

    left     : calc(160vh - 15vw);
    top      : 75%;
    transform: translate(-50%-40, -50%);
}

.slider-landing-leftrect-1{
    background: white;
    border-radius: 3vh 0 0 0;
    border: 2px white solid;
    position : absolute;
    top: -1px;
    left: -1px;
    width: 15%;
    height: 35%;
}

.slider-landing-rightrect-1{
    background: white;
    border-radius: 0 3vh 0 0;
    border: 2px white solid;
    position : absolute;
    top: -1px;
    right: -1px;
    width: 70%;
    height: 15%;
}

.slider-landing-leftrect-2{
    background: white;
    border-radius: 3vh 0 0 0;
    border: 2px white solid;
    position : absolute;
    top: -1px;
    left: -1px;
    width: 10%;
    height: 20%;
}

.slider-landing-rightrect-2{
    background: white;
    border-radius: 0 3vh 0 0;
    border: 2px white solid;
    position : absolute;
    top: -1px;
    right: -1px;
    width: 50%;
    height: 15%;
}

.slider-landing-leftrect-3{
    background: white;
    border-radius: 3vh 0 0 0;
    border: 2px white solid;
    position : absolute;
    top: -1px;
    left: -1px;
    width: 10%;
    height: 80%;
}

.slider-landing-rightrect-3{
    background: white;
    border-radius: 0 3vh 0 0;
    border: 2px white solid;
    position : absolute;
    top: -1px;
    right: -1px;
    width: 20%;
    height: 30%;
}