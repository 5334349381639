@import "../../style/variables.scss";
 
.prismic-container{
     z-index: var(--ztop);
     position:fixed;
     width: 60%;
     height: 60%;
     left: 50%;
     top: 50%;
     transform: translate(-50%, -50%);
     background: white;
     border-radius: var(--radius2);
     
     @include shadow(8px, 0.4);
     @media only screen and (max-width: $secondbrekpoint) {
        width : 85%;
    }
    overflow-wrap: break-word;
    word-wrap: break-word;
 }
 

 .prismic-content{
     position:fixed;
     width: 100%;
     height: 100%;
     overflow: auto;
    
     color: var(--pc);
     font-size: var(--normal);
     font-family: "GINTON", "Helvetica Neue", arial, sans-serif;
 }

 .prismic-title{
    padding: $lh/1.6;
    padding-bottom: 0;
    padding-top: $lh/5;
    padding-right: $lh * 2;
     font-size: var(--big);
     text-transform: uppercase;
    //  margin-bottom: $lh/3;
     font-family: "GINTON", "Helvetica Neue", arial, sans-serif;
 }

 .prismic-subtitle{
    padding: $lh/1.2;
    padding-bottom: 0;
    padding-top: 0;
     font-size: var(--normal2);
     text-transform: uppercase;
    //  margin-bottom: $lh/3;
     font-family: "GINTON", "Helvetica Neue", arial, sans-serif;
 }

 .prismic-text{
     color: var(--pc);
     max-width: 100%;
     padding: $lh;
     padding-top: 0;
     padding-bottom: 0;
     font-size: var(--normal);
     font-family: "GINTON", "Helvetica Neue", arial, sans-serif;
     img {
         max-width: 60%;
         border: 2px white solid;
         filter: drop-shadow(7px 6px 5px rgba(0,0,0,0.2));
         border-radius: var(--radius3);
         margin-top: $lh/2;
         margin-bottom: $lh/2;
     }
     video{
        max-width: 60%;
     }
     a{
         color: var(--pc);
         text-decoration: none;
         pointer-events: none;
     }
     a:hover{
         text-decoration: none;
     }
 }

 .prismic-links{
    pointer-events: all !important;
        cursor: pointer;
        color: var(--pc) !important;
        text-decoration: underline !important;
 }

 .prismic-links:hover{
    text-decoration: none !important;
 }

 .embed-youtube{
    margin-top: $lh/2;
    margin-bottom: $lh/2;
    
    iframe{
        border: 2px white solid;
         filter: drop-shadow(7px 6px 5px rgba(0,0,0,0.2));
         border-radius: var(--radius3);
        width: 60%;
        height: auto;
    }
 }

 .prismic-text-section{
    padding: 0;
    padding-bottom: $lh/2;
    font-size: var(--normal);
    font-family: "GINTON", "Helvetica Neue", arial, sans-serif;
}

.prismic-image-container{
    width: 100%;
    height: 100%;
    padding: $lh;
}

 .prismic-image{
    max-width: 100%;
    max-height: 102%;

     border: 2px white solid;
     filter: drop-shadow(7px 6px 5px rgba(0,0,0,0.2));
     border-radius: var(--radius3);
 }

 .prismic-image-section{
    margin-top: $lh/3;
    margin-bottom: $lh/3;
    max-width: 80%;
 }

 .prismic-line{
     height: 1px;
     width: 100%;
     background: var(--pc);
     margin-top: $lh/2;
     margin-bottom: $lh/2;
 }

 //---loading

 .prismic-loading-wrapper{
     position:fixed;
     width: 100%;
     top: 50%;
     transform: translateY(-50%);
 }
 
 .prismic-loading-text{
     text-align: center;
     font-size: var(--medium);
     font-family: "GINTON", "GINTO", "Helvetica Neue", arial, sans-serif;
     color: var(--pc);
 }
 
   .prismic-loader {
     margin-left: auto;
     margin-right: auto;
     margin-bottom: $lh/2;
     display: block;
 
     border: 2px solid rgba(0,0,0,0); /* Light grey */
     border-top: 2px solid var(--pc); /* Blue */
     border-radius: 50%;
     width: $lh * 2;
     height: $lh * 2;
     animation: spin 2s linear infinite;
   }
   
   @keyframes spin {
     0% { transform: rotate(0deg); }
     100% { transform: rotate(360deg); }
   }