@import "../../style/variables.scss";

.character {
    border-radius: var(--radius);
    padding: $lh;
    padding-bottom: $lh/4;
}

.character-gray {
    background-color: var(--lightgray);
}

.character-input{
    background-color: var(--lightgray);
    border-radius: 0;
    border: none;
    font-size: var(--medium);
    margin-top: $lh/3;
    padding: $lh/6;
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
    @media only screen and (max-width: $firsbrekpoint) {
        width: $lh *7;
    }
    width: 100%;
}

::placeholder {
    color: var(--mediumgray);
    opacity: 1; /* Firefox */
  }
  
:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color: var(--mediumgray);
}
  
::-ms-input-placeholder { /* Microsoft Edge */
   color: var(--mediumgray);
}



.character-variable{
    cursor: pointer;
    width: 100%;
    height: 100%;
    margin-bottom: $lh/4;
    filter: drop-shadow(0px 0px 8px rgba(255, 255, 255, 0.3));
    transition: 500ms all;

}

.character-variable:hover{
    opacity: 0.5;
}


.character-image {
    width: 100%;
    height: 100%;
    border-radius: 100%;
}

.character-image-container{
    
    position: relative;
    width: $lh * 5;
    height: $lh * 5;

    margin-left: auto;
    margin-right: auto;

    display: block;
}

.character-headline{
    color: var(--dark);
    text-align: center;
    margin-bottom: 1em;
    font-size: var(--small);
}

.character-text{
    color: var(--dark);
    text-align: center;
    margin-bottom: 1em;
    margin-top: 1em;
    font-size: var(--small);
}