@import "../../style/variables.scss";

.avatar {
    position: fixed;
    top: 0;
    left: 0;
    // width: 2em;
    // height: 2em;
    // background: red;
    z-index: var(--ztop);
    pointer-events: none;
    text-align: center;
    // display: none;
    transition: 1s all;
    will-change: transform;
    z-index: 10000;

    div {
        font-size: 0.5em;
        margin-bottom: 0.2em;
    }

    img {
        width: 54px;
        border: solid 2px white;
        border-radius: 100em;
    }

    @media only screen and (max-width: $secondbrekpoint) {
        display: none;
    }
}