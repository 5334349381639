@font-face {
    font-family: 'GINTO'; 
    src: url('../public/fonts/GintoNormal-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GINTOB'; 
    src: url('../public/fonts/GintoNormal-Bold.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GINTON'; 
    src: url('../public/fonts/GintoNord-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GINTONB'; 
    src: url('../public/fonts/ABCGintoNord-Medium.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}





$firsbrekpoint: 870px;

* {
    box-sizing: border-box;
    hyphens: none;
    user-select: none;
    outline: none;
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0); /* mobile webkit */ 
}


html, body {
    overflow: hidden;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background: yelow;
    font-family: 'GINTO','Helvetica Neue', arial, sans-serif;
    text-rendering: geometricPrecision;
    -webkit-font-smoothing: antialiased;
    background: rgb(23, 23, 23);
    color: white;
    font-size: 18px;
    overscroll-behavior-x: none;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    @media only screen and (max-width: $firsbrekpoint) {
        font-size: 14px;    
    }
}

video {
    object-fit: cover;
}

// // *:fullscreen {
// //     background: red;
//     video {
//         object-fit: contain;
//     }
// }

video:fullscreen {
    object-fit: contain;
}

::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
}
