@import "../style/variables.scss";

.donate-container{
    background: var(--tc);
    min-height: 100%;
}

.donate {
    overflow: scroll;
    padding: $lh;
    padding-top: $lh * 3;
    // z-index: var(--ztop);
    height: 100%;
    width: 100;
    background-image: url("../public/images/scenes/INFLATABLE_03_0041_RGB_blau.jpg");
    background-color: var(--tc);
    background-size: 200vw;
    background-repeat: no-repeat;
    background-position: center top;
}

.donation-note {
    font-size: 1rem;
}

.donation-predefined-amount {
    background: var(--grayalpha);
    font-size: var(--medium);
    font-family: "GINTON", "GINTO", "Helvetica Neue", arial, sans-serif;
    border: white 2px solid;
    color: white;
    padding: $lh/6;
    width: 6em;
    text-align: center;
    margin-right: $lh / 2;
    cursor: pointer;
    transition-duration: 0.3s;
    @media only screen and (max-width: $firsbrekpoint) {
        width: 20%;
    }
}

.donation-predefined-amount-active {
    background: white;
    color: var(--tc);
}

.donation-predefined-amount:hover {
    opacity: 0.5;
}

.donation-predefined-container {
    margin-top: $lh / 3;
    margin-bottom: $lh / 3;
}

.donate-submit {
    cursor: pointer;
    text-transform: uppercase;
    font-family: "GINTON", "GINTO", "Helvetica Neue", arial, sans-serif;
    background: white;
    font-size: var(--medium);
    color: var(--tc);
    padding: $lh/6;
    width: 10em;
    text-align: center;
    margin-right: $lh / 2;
    margin-top: $lh /3;
    transition-duration: 0.3s;
    @media only screen and (max-width: $firsbrekpoint) {
        width: 25vh;
    }
}

.donate-submit:hover{
    opacity: 0.85;
}

.donation-custom-amount-button {
    background: none;
    font-size: inherit;
    border: white 2px solid;
    color: white;
    padding: $lh;
    width: 16em;
    text-align: center;
    margin-right: $lh / 2;
    cursor: pointer;
    // opacity: 0.5;
    // transition-duration: 0.3s;
}

.donation-custom-amount-button-active {
    background: white;
    color: var(--sc)
}


.donate-amount-input {
        background: var(--grayalpha);
        font-size: var(--medium);
        font-family: "GINTON", "GINTO", "Helvetica Neue", arial, sans-serif;
        border: 2px solid white;
        border-radius: 0;
        color: white;
        padding: $lh/6;
        margin-top: $lh / 3;

        width: 6em;
        transition-duration: 0.3s;
        @media only screen and (max-width: $firsbrekpoint) {
            width: 25vh;
        }
}

.donate-custom-amout::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.donate-custom-amout:hover {
    opacity: 0.8;

}



.donate-consent {
    margin-top: $lh;
    width: 2em;
    height: $lh;
    width: $lh;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    border: 2px solid white;
    border-radius: 0;
    outline: none;
    transition-duration: 0.3s;
    background-color: rgba(255,255,255, 0.2);
    cursor: pointer;
    margin-right: $lh;
  }


  .donate-consent:checked {
    border: 2px solid white;
    background-color: white;
}

.donate-error {
 color: var(--pc);
 font-size: var(--medium);
 margin-top: $lh/3;
 font-family: "GINTON", "GINTO", "Helvetica Neue", arial, sans-serif;
}

.intro-text{
    width: 50%;
    padding: $lh/6;
    font-size: var(--medium);
    font-family: "GINTON", "GINTO", "Helvetica Neue", arial, sans-serif;
    background: var(--grayalpha);
    margin-top: $lh/3;
    margin-bottom: $lh/3;
    @media only screen and (max-width: $firsbrekpoint) {
        width: 100%;
    }
}

.donate-text{

    text-transform: uppercase;
}

.donate-loading-bg{
    z-index: var(--ztop);
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: var(--grayalpha);
    top: 0;
    left: 0;
}

.donate-loading-wrapper{
    position: absolute;
    width: $lh * 10;
    height: $lh * 3;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.donate-loading-text{
    text-align: center;
    font-size: var(--medium);
    font-family: "GINTON", "GINTO", "Helvetica Neue", arial, sans-serif;
}

  .donate-loader {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: $lh/2;
    display: block;

    border: 2px solid rgba(0,0,0,0); /* Light grey */
    border-top: 2px solid var(--white); /* Blue */
    border-radius: 50%;
    width: $lh * 2;
    height: $lh * 2;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }


  .back-to-front {
      text-decoration: none;
      display: inline-block;
    cursor: pointer;
    text-transform: uppercase;
    font-family: "GINTON", "GINTO", "Helvetica Neue", arial, sans-serif;
    background: white;
    font-size: var(--medium);
    color: var(--tc);
    padding: $lh/6;
    min-width: 25em;
    text-align: center;
    margin-right: $lh / 2;
    margin-top: $lh;
    transition-duration: 0.3s;
    @media only screen and (max-width: $firsbrekpoint) {
        width: 25vh;
    }

  }

  .back-to-front:hover {
      opacity: 50%;
  }