@import "../../style/variables.scss";

//---- SECRET PAGE

.portals-secret-form{
     position: fixed;
     left: 50%;
     top: 50%;
     transform: translate(-50%, -50%);
     height: $lh*3;
}

.portals-secret-wrong{
     margin-top: $lh/5;
     text-align: center;
     color: var(--pc);
     font-size: var(--normal2);
}

.portals-secret-logged{
     width: 100%;
     height: 100%;
     padding: 1em;
}

.portals-secret-form-label{
     margin-right: 5px;
     font-size: var(--medium);
     background-color: var(--grayalpha);
     border-radius: 0;
     height: 2em; 
     border: none;
     border: 2px white solid;
     color: white;
     padding-left: $lh/4;
}

.portals-secret-leftcolumn{
     width: 50%;
     padding-right: $lh*2;
     float: left;
     @media only screen and (max-width: $firsbrekpoint) {
          position: static;
          width: 100%;
          padding-right: 0;
          float: none;
     }
}

.portals-secret-rightcolumn{
     color: var(--white);
     width: 50%;
     padding-left: $lh/2;
     float: right;
     padding-bottom: $lh*3;
     @media only screen and (max-width: $firsbrekpoint) {
          width: 100%;
          padding-left: 0;
          float: none;
     }
}

.portals-leftcolumn{
     width: 50%;
     padding-right: $lh*2;
     float: left;
     @media only screen and (max-width: 1200px) {
          position: static;
          width: 100%;
          padding-right: 0;
          float: none;
     }
}

.portals-rightcolumn{
     color: var(--white);
     width: 50%;
     padding-left: $lh/2;
     float: right;
     padding-top: $lh*3;
     @media only screen and (max-width: 1200px) {
          width: 100%;
          padding-left: 0;
          padding-top: $lh;
          float: none;
     }
}

.portals-secret-headline{
     font-size: var(--big);
     text-transform: uppercase;
     padding-right: $lh * 2;
    font-family: "GINTON", "GINTO", "Helvetica Neue", arial, sans-serif;
}

.portals-secret-description{
     font-size: var(--normal2);  
     margin-bottom: $lh/3;
    font-family: "GINTON", "GINTO", "Helvetica Neue", arial, sans-serif;
}

.portals-description{
     font-size: var(--normal2);  
     margin-bottom: $lh/3;
    font-family: "GINTON", "GINTO", "Helvetica Neue", arial, sans-serif;
}

.portals-description2{
     font-size: var(--small2);  
     margin-bottom: $lh/3;
    font-family: "GINTON", "GINTO", "Helvetica Neue", arial, sans-serif;
}

.portals-secret-player-container{
     width: 100%;
     background: rgb(241, 243, 244);
     padding: $lh/2;
     margin-top: $lh;
     margin-bottom: $lh;
     border-radius: var(--radius3);
}

.portals-secret-player{
     margin-left: auto;
     margin-right: auto;
     display: block;
     width: 100%;
}

.portals-secret-image{
     max-width: 60%; 
     max-height: 60vh;
     border: 2px white solid;
     filter: drop-shadow(7px 6px 5px rgba(0,0,0,0.2));
     border-radius: var(--radius3);
     background: white;
     float: right;
     @media only screen and (max-width: $firsbrekpoint) {
          float: none;
          max-width: 100%; 
          max-height: 50vh;
          margin-bottom: $lh/2;
     }
 }

.portals-line{
     width: 100%;
     height: 1px;
     border: 1px white solid;
     margin-top: $lh/2;
     margin-bottom: $lh/2;
}


//--------

 .portals-text-container{
     margin-bottom: $lh *5;
     column-count: 2;
     @media only screen and (max-width: 1200px) {
         margin-left: 0;
         margin-right: 0;
         column-count: 1;
     }
 }


 .portals-title{
     font-size: var(--medium2);
     font-family: "GINTON", "GINTO", "Helvetica Neue", arial, sans-serif;
     text-transform: uppercase;
     padding-right: $lh* 2;
     margin-bottom: $lh/10;
     position: relative;
     @media only screen and (max-width: $thirdbrekpoint) {
         padding: $lh/3;
     }
 }


.portals-image{
     cursor: pointer;
    width: 100%; 
    display: block;
    margin-left: auto;
    margin-right: auto;
    border: 2px white solid;
    filter: drop-shadow(7px 6px 5px rgba(0,0,0,0.2));
    border-radius: var(--radius3);
    background: white;
    margin-bottom: $lh/2;
    margin-top: $lh/2;
}

.portals-image:hover{
     filter: brightness(0.85) drop-shadow(7px 6px 5px rgba(0,0,0,0.2));
}

.portals-close-container{
     font-size: var(--medium2);
     position: absolute;
     top: 0;
     right:0;
     height: $lh * 1.7;
     width: $lh * 1.7;
     border-bottom: 0px;
     padding: $lh/3;
 }

 .portals-link{
      text-decoration: underline;
      color:white;
 }

 .portals-link:hover{
     color: var(--lightgray);
      text-decoration: none;
 }

 .portals-image2{
     cursor: pointer;
    width: 100%; 
    display: block;
    margin-left: auto;
    margin-right: auto;
   
    background: white;
}