@import "../../style/variables.scss";

.video-container{
     position: fixed;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
     background: white;
     height: 70vh;
     width: 81vw;
     z-index: var(--ztop);

     border-radius: var(--radius2);
     @include shadow(8px, 0.4);

     overflow-wrap: break-word;
     word-wrap: break-word;

     @media only screen and (max-width: $firsbrekpoint) {
        height: 96%;
        width: calc(100vw - 4vh);
      }

}

.video-scroll{
     position: absolute;
     overflow-y: scroll;
     height: 100%;
     width: 100%;

     padding: 3vh;
     padding-right: 1vh;
     
}


// .video-scroll::-webkit-scrollbar {
//     width: auto;
// }



.video-title-container{
     width: 100%;
    padding-right: 5vh;
     color: var(--pc);
     font-size: var(--normal);
     font-family: "GINTON", "Helvetica Neue", arial, sans-serif;
}

.video-title{
      font-size: var(--big);
      text-transform: uppercase;
     //  margin-bottom: $lh/3;
      font-family: "GINTON", "Helvetica Neue", arial, sans-serif;
  }

  .video-subtitle{
      font-size: var(--normal2);
     //  text-transform: uppercase;
     //  margin-bottom: $lh/3;
      font-family: "GINTON", "Helvetica Neue", arial, sans-serif;
  }

  .video-content{
     color: var(--pc);
     width: 100%;
     margin-top: $lh;
     margin-bottom: 5vh;
  }

  .video-tile{
       position: relative;
     float: left;
     height: auto;
     width: 25%;
     margin-bottom: 2vh;
     padding-right: 2vh;
    //  padding-left: 1vh;
    @media only screen and (max-width: 1300px) {
        width: 33.3%;
    }
     @media only screen and (max-width: 1000px) {
          width: 50%;
      }
      @media only screen and (max-width: $secondbrekpoint) {
          width: 100%;
      }
  }

  .video-tile-text{
      margin-top: 1vh;
     font-size: var(--small);
     height: 5vh;
  }

  .video-player-container {
    cursor: pointer;
     position     : relative;
     top          : 1px;
     height       : 0;
     overflow     : hidden;
     position     : relative;
     background   : black;
     padding-top  : 16px / 9px * 30%;
     border-radius: 1vh;
     border       : 2px white solid;
     filter: drop-shadow(7px 6px 5px rgba(0,0,0,0.2));
 }

 .video-player-container:hover{
    filter: brightness(0.8) drop-shadow(7px 6px 5px rgba(0,0,0,0.2));
 }
 
 .video-player {
     background: black;
    pointer-events: none;
     position: absolute;
     top     : 0;
     left    : 50%;
     transform: translateX(-50%);
     width   : 105%;
     height: 105%;
 }



 .video-player-play{
      z-index: var(--ztop);
      cursor: pointer;
     position: absolute;
     top     : 0;
     left    : 50%;
     transform: translateX(-50%);
     width   : 100%;
     height : 50%;
     padding: 2.5vh 10vh 2.5vh 10vh;
     opacity: 0;
 }

 .video-player-play:hover{
    opacity: 0.5; 
 }



//    //---loading

//  .timetable-loading-wrapper{
//      position:fixed;
//      width: 100%;
//      top: 50%;
//      transform: translateY(-50%);
//  }
 
//  .timetable-loading-text{
//      text-align: center;
//      font-size: var(--medium);
//      font-family: "GINTON", "GINTO", "Helvetica Neue", arial, sans-serif;
//      color: var(--pc);
//  }
 
//    .timetable-loader {
//      margin-left: auto;
//      margin-right: auto;
//      margin-bottom: $lh/2;
//      display: block;
 
//      border: 2px solid rgba(0,0,0,0); /* Light grey */
//      border-top: 2px solid var(--pc); /* Blue */
//      border-radius: 50%;
//      width: $lh * 2;
//      height: $lh * 2;
//      animation: spin 2s linear infinite;
//    }
   
//    @keyframes spin {
//      0% { transform: rotate(0deg); }
//      100% { transform: rotate(360deg); }
//    }
 
 
 