@import "variables.scss";
@import "default.scss";
@import "instructions.scss";
@import "details.scss";
@import "installation.scss";
@import "variableCss.scss";

#root {
    width: 100%;
    height: 100%;
}

.renderCanvas {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    touch-action: none;
    z-index: 10;
}

.main {
    position: absolute;
    top: 0;
    left: 0;
    color: $pc;
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}
.main-container {
    width: 100%;
    height: 100%;
}
canvas {
    width: 100%;
    height: 100%;
}

.artwork-container {
    position: fixed;
    width: 100%;
    height: 100%;
    background: black;
    backdrop-filter: blur(20px);
    top: 0;
    left: 0;
    z-index: 10;
    color: white;
    padding: 10%;
    padding-top: $lh * 3;
    padding-bottom: $lh;
    overflow: scroll;

    @media only screen and (max-width: $firsbrekpoint) {
        padding: 5%;
        padding-top: $lh * 6;
    }
}

.artwork-videoplayer {
    width: 100%;
    height: 100%;

    iframe {
        background: black;
        width: 100%;
        height: 100%;
    }
}

.video-container {
    width: 100%;
    position: relative;
    background: white;
    border-radius: $radius;

    .video-container-content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: $radius;
        overflow: hidden;
    }
}

.video-container-after {
    content: "";
    width: 100%;
    display: block;
    height: 0;
    width: 100%;
}

.mt-1 {
    margin-top: $lh;
}

.artwork-caption {
    margin-top: $lh * 2;
    opacity: 1;
}

canvas {
    z-index: 100;
}

textarea {
    font-size: inherit;
    border: none;
    resize: none;
    height: $lh;
}

.small {
    font-size: 0.75em;
}


//----

.border {
    border: solid;
    border-color: white;
    border-width: 2.5px;
    @include shadow(8px, 0.4)
}

.border-noshadow {
    border: solid;
    border-color: white;
    border-width: 2.5px;
}

.border-dark {
    border: solid;
    border-color: var(--dark);
    border-width: 2.5px;
}

.border-red{
    border: solid;
    border-color: var(--pc);
    border-width: 2.5px;
}

.logo{
    background-image: url('../public/images/svg/logo.svg/');
    background-repeat: no-repeat;
    z-index: var(--ztop);
    width: $lh * 11;
    height: $lh*1.4;
    position: fixed;
    padding-left: $lh / 10;
    padding-right: $lh / 10;
    top: $lh / 1.2;
    @include shadow(8px, 0.3);

    @media only screen and (max-width: $secondbrekpoint) {
        padding-left: $lh / 10;
        padding-right: $lh / 10;
    }
}

.logo-left{
    left: $lh;
}

.logo-scene{
    right: 50%;
    transform: translateX(50%);
    @media only screen and (max-width: $secondbrekpoint) {
        right: $lh;
        transform: translateX(0%);
    }
    @media only screen and (max-width: $thirdbrekpoint) {
        left: $lh*0.8;
    }
}


.button-enter-temp {
    font-size: 1rem !important;

    a {
        color: inherit !important;
        text-decoration: none;
    }
}