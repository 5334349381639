@import "../../style/variables.scss";

.privacy-container{
     overflow: scroll;
     padding-top: $lh * 4;
     padding-bottom: $lh * 4;
     height: 100%;
     z-index: 1000;

     p {
     margin-top: 0;
     }

     @media only screen and (max-width: $firsbrekpoint) {
     width: 100%;
     }
}

.privacy{
     padding: var(--lh);
     overflow: scroll;
     z-index: 1000;
     
}