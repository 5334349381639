@import "../../style/variables.scss";

.auth {
    position: absolute;
    top: 50%; 
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: var(--zlanding2);
    @media only screen and (max-width: $firsbrekpoint) {
        top: 48%;  
    }  
}

.auth-title {
    background: white;
    width: $lh * 14;

    padding: $lh / 2;
    border-radius: var(--radius2);;
    text-align: center;
    display: block;
    filter: var(--shadow);

    @media only screen and (max-width: $firsbrekpoint) {
        width: $lh * 14;
        margin-bottom: $lh/2; 
    }
}

.auth-title-short{
    height: $lh * 10;
}

.auth-title-tall{
    height: $lh * 19;
    @media only screen and (max-width: $firsbrekpoint) {
        height: $lh * 17;

    }
}

.auth-welcome{
    color: var(--pc);
    font-size: var(--medium);
    margin-bottom: 2em;

    a {
        color: inherit;
    }
}

// .auth-3d {
//     // background: linear-gradient(90deg,var(--dark) 24%,var(--pc) 43%,var(--pc) 63%,var(--pc) 80%,var(--dark) 100%);
//     background-color: var(--white);
//     animation: highlight 9s linear infinite;
//     background-size: 200% 100%; 
// }

@keyframes highlight{0%{background-position:-20%}to{background-position:180%}}

.landing-player{
    background: $tc;
    border-radius: var(--radius2);
    z-index: 900;
    width: 20em;
    padding: $lh;
    backdrop-filter: blur(6px);
    filter: drop-shadow(0px 0px 4px rgba(0,0,0,0.8));
    text-align: center;
    display: block;
    margin-bottom: $lh;

    @media only screen and (max-width: $firsbrekpoint) {
        width: 17em; 
    }
}

.landing-welcome {
    font-size: var(--big2);
    color: white;
    padding: 1em;
    padding-bottom: 2em;
}

.landing-recommended{
    position: absolute;
    left: 50%;
    bottom: $lh;
    transform: translateX(-50%);
    width: 90%;

    line-height: $lh * 0.8;
    opacity: 0.7;
    font-size: var(--small);
    color: var(--pc);
}

.landing-shortname{
    color: var(--pc);
    font-size: var(--small);
    height: $lh;
    margin-bottom: $lh*1.5;
}


.landing-loading-wrapper{
    position: absolute;
    width: $lh * 10;
    height: $lh * 3;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.landing-loading-text{
    text-align: center;
    font-size: var(--medium);
    font-family: "GINTON", "GINTO", "Helvetica Neue", arial, sans-serif;
}

  .landing-loader {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: $lh/2;
    display: block;

    border: 2px solid rgba(0,0,0,0); /* Light grey */
    border-top: 2px solid var(--white); /* Blue */
    border-radius: 50%;
    width: $lh * 2;
    height: $lh * 2;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }