@import "../../style/variables.scss";

.overview-container {
     padding: $lh;
     padding-top: 0;
     padding-bottom: 0;
     display: grid;
     grid-template-columns: 1fr;
     column-gap: $lh;
     height: 100vh;
     background: white;
     color: var(--pc);
     z-index: var(--ztop);
     position: fixed;
     top: 0;
     left: 0;
     width: 50%;
     @include shadow(8px, 0.4);
 
     p {
         margin-top: 0;
     }
 
     @media only screen and (max-width: $firsbrekpoint) {
         width: 100%;
     }
 }

 .overview{
     padding: 0;
    overflow: scroll;
    width: 100%;
    height: 103vh;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
 }

 .overview-h1 {
    font-size: var(--big);
    text-transform: uppercase;
    font-family: "GINTON", "GINTO", "Helvetica Neue", arial, sans-serif;
    padding-bottom: 1vh;
    padding-right: 3vh;
 }

 .overview-h2{
    font-family: "GINTOB", "GINTO", "Helvetica Neue", arial, sans-serif;
 }


 .popup-container{
     
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    height: 70vh;
    width: 81vw;
    z-index: var(--ztop);
    color: var(--pc);

    border-radius: var(--radius2);
    @include shadow(8px, 0.4);

    overflow-wrap: break-word;
    word-wrap: break-word;

    @media only screen and (max-width: $firsbrekpoint) {
         height: 96%;
         width: calc(100vw - 4vh);
     }

}

.popup-scroll{
    position: absolute;
    overflow-y: scroll;
    height: 100%;
    width: 100%;

    padding: 3vh;
   
}

.popup-text{
    font-size: var(--normal);
    margin-bottom: 4vh;
    user-select: text;
    a {
        color: var(--pc);
        user-select: text;
    }
    a:hover{
        text-decoration: none;
    }
}

.popup-headline{
    font-size: var(--big);
    text-transform: uppercase;
    font-family: "GINTON", "GINTO", "Helvetica Neue", arial, sans-serif;
    padding-bottom: 1vh;
    padding-right: 4vh;
}

.popup-bold{
    font-family: "GINTOB", "GINTO", "Helvetica Neue", arial, sans-serif;
}

