@import "../../style/variables.scss";
@import "../ui/buttons.scss";


.clifford-container {
    position: absolute;
    top: $lh * 6.4;
    left: $lh * 3;
    width: 10em;
    height: 10em;
    z-index: 1000;
}

.clifford-container-fullscreen{
    position: absolute;
    top: 50%;
    transform: translateY(-50%) ;
    width: 10em;
    height: 10em;
    z-index: 1000;
}