@import "../../style/variables.scss";

.player-container{
     padding: $lh;
     padding-left: $lh;
     padding-top: $lh * 4;
     display: grid;
     grid-template-columns: 1fr;
     column-gap: $lh;
     overflow: scroll;
     z-index: 900;
     position: fixed;
     top: 0;
     left: 0;

     p {
     margin-top: 0;
     }

     @media only screen and (max-width: $firsbrekpoint) {
     width: 100%;
     }
}