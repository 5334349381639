@import "../../style/variables.scss";

.section-window {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: var(--zsectionwindow);
    background: var(--tc);
    padding: $lh;
    padding-top: $lh * 3;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    
}

.section-window-secret{
    position: relative;
    background: var(--dark);
}

.section-fullscreen{
    position: fixed;
    z-index: var(--ztop);
    background: black;
    width: 100vw;
    height: 100vh;
}

.section-fullscreen-player{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.section-container{
    overflow-x: hidden;
    padding-left: $lh * 2;
    padding-right: $lh * 2;
    @media only screen and (max-width: $firsbrekpoint) {
        padding-left: 0;
        padding-right: 0;
    }
    @media only screen and (max-width: $thirdbrekpoint) {
        margin-top: $lh * 2;
    }
}

.section-container-portals{
    overflow-x: hidden;
    padding: $lh;
    position: relative;
    border: 2px white solid;
    background: var(--grayalpha);
    @media only screen and (max-width: $thirdbrekpoint) {
        margin-top: $lh * 2;
    }
    margin-bottom: 14vh;
}



//--

.section-margin-left1{
    margin-left: $lh/3;
    @media only screen and (max-width: $firsbrekpoint) {
        margin-left: $lh/2;
    }
    @media only screen and (max-width: $thirdbrekpoint) {
        margin-right: 0;
    }
}

.section-margin-left2{
    margin-left: $lh *3;
    @media only screen and (max-width: $firsbrekpoint) {
        margin-left: $lh/2;
    }
    @media only screen and (max-width: $thirdbrekpoint) {
        margin-left: 0;
    }
}

.section-margin-right1{
    margin-right: $lh /2;
    @media only screen and (max-width: $firsbrekpoint) {
        margin-right: $lh * 10;
    }
    @media only screen and (max-width: $secondbrekpoint) {
        margin-right: $lh/2;
    }
    @media only screen and (max-width: $thirdbrekpoint) {
        margin-right: 0;
    }
}
.section-margin-right2{
    margin-right: $lh *3;
    @media only screen and (max-width: $firsbrekpoint) {
        margin-right: $lh /2;
    }
    @media only screen and (max-width: $thirdbrekpoint) {
        margin-right: 0;
    }
}
.section-width1{
    width: calc(100% - 19em);
    @media only screen and (max-width: $firsbrekpoint) {
        width: auto;
    }
}
.section-width2{
    width: calc(100% - 10em);
    @media only screen and (max-width: $firsbrekpoint) {
        width: auto;
    }
}
.section-fullscreen1{
    position: absolute;
    top: $lh * 3.7;
    right: $lh * 11.6;
    @media only screen and (max-width: $firsbrekpoint) {
        right: $lh * 7.9;
    }
    @media only screen and (max-width: $secondbrekpoint) {
        right: $lh * 1.1;
    }
    @media only screen and (max-width: $thirdbrekpoint) {
        top: $lh * 5.1;
        right: $lh * 0.7;
    }
}

.section-fullscreen2{
    position: absolute;
    top: $lh * 3.7;
    right: $lh *5.1;
    @media only screen and (max-width: $firsbrekpoint) {
        right: $lh * 1.1;
    }
    @media only screen and (max-width: $thirdbrekpoint) {
        top: $lh * 5.1;
        right: $lh * 0.7;
    }
    
}

//--

.section-close-container{
    font-size: var(--medium2);
    float: right;
    height: $lh * 1.7;
    width: $lh * 1.7;
    background: rgb(94,106,180);
    border: 2px white solid;
    border-radius: 10px 10px 0px 0px;
    border-bottom: 0px;
    padding: $lh/3;
}

.section-fullscreen-button-container{
    cursor: pointer;
    font-size: var(--medium2);

    height: $lh * 1.7;
    width: $lh * 1.7;
 
    padding: $lh/2;
    z-index: var(--ztop);
}

.section-fullscreen-button-container:hover{
    opacity: 0.6;
}

.section-headline-container{
    font-size: var(--medium2);
    font-family: "GINTON", "GINTO", "Helvetica Neue", arial, sans-serif;
    text-transform: uppercase;
    float: left;
    height: $lh * 1.7;
    max-width: $lh * 10;
    background: rgb(94,106,180);
    border: 2px white solid;
    border-radius: 10px 10px 0px 0px;
    border-bottom: 0px;
    padding: $lh/3;
    padding-left: $lh/2;
    padding-right: $lh;

    @media only screen and (max-width: $thirdbrekpoint) {
        padding: $lh/3;
    }
}



.top-line{
    border-bottom: 2px white solid;
    height: $lh * 1.7;
    font-size: var(--medium2);
}

.bottom-line{
    border-bottom: 2px white solid;
    height: $lh * 1.7;
    font-size: var(--medium2);
}

.section-player-container{
    height: 0;
    overflow: hidden;
    position: relative;
    background: var(--dark);
    padding-top: 56.25%;
    border-left: 2px white solid;
    border-right: 2px white solid;
    border-bottom: 2px white solid;
}


.section-player{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.section-audioplayer-container{
    height: 0;
    overflow: hidden;
    position: relative;
    background: #5e6ab4;
    padding-top: 15%;
    border-left: 2px white solid;
    border-right: 2px white solid;
    border-bottom: 2px white solid;
}

.section-audioplayer{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40%;
    height: $lh*2;
}

.section-text-container{
    background: rgb(94,106,180);
    border: 2px white solid;
    border-top: 0;
    border-radius: 0px 0px 10px 10px;
    margin-left: $lh* 2;
    margin-right: $lh* 2;
    margin-bottom: $lh *2;
    padding: $lh/2;
    padding-top: 0;
    padding-bottom: 0;
    position: relative;
    bottom: 2px;
    @media only screen and (max-width: $secondbrekpoint) {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: $lh *3;
    }
}

.section-text-artist{
    padding-top: $lh/5;
    font-size: var(--medium);
    font-family: "GINTON", "GINTO", "Helvetica Neue", arial, sans-serif;
    
}

.section-text-show{
    font-size: var(--medium);
    line-height: $lh * 0.9;
}

.section-text-time{
    // position: absolute;
    // right: $lh/2;  
    font-size: var(--normal2);  
    font-family: "GINTON", "GINTO", "Helvetica Neue", arial, sans-serif;
    
}


.section-text-description{
    margin-top: $lh/1.5;
    font-size: var(--normal);  
    font-family: "GINTON", "GINTO", "Helvetica Neue", arial, sans-serif;
}

.section-masked{
    cursor: pointer;
    background-color: var(--grayalpha);
    position: relative;
}
