@import "../../style/variables.scss";

.timetable-container {
    padding-top: 0;

    height    : 100%;
    background: white;
    color     : var(--pc);
    z-index   : var(--ztop);
    position  : fixed;
    top       : 0;
    left      : 0;
    width     : 70%;
    @include shadow(8px, 0.4);

    @media only screen and (max-width: $firsbrekpoint) {
        width: 100%;
    }
}


// ---- Tabs

.timetable-fixed {
    position     : absolute;
    width        : 100%;
    background   : white;
    border-bottom: 1px white solid;
    @include shadow(8px, 0.4);
}

.timetable-tab {
    cursor        : pointer;
    display       : inline-block;
    padding       : $lh/2.5;
    font-size     : var(--medium);
    font-family   : "GINTON", "Helvetica Neue", arial, sans-serif;
    text-transform: uppercase;
    text-align    : center;
    width         : calc((100% - 64px) / 4);
    height        : auto;
}

.timetable-tab-active {
    color     : var(--pc);
    background: var(--white);

}

.timetable-tab-inactive {
    color      : var(--white);
    background : var(--pc);
    border-left: 1px white solid;
}

.timetable-tab-inactive:hover {
    // background: var(--lightgray)
    opacity: 0.7;
}

// --- Content

.timetable-scroll-container {
    height  : 105%;
    width   : 100%;
    z-index : 0;
    position: absolute;
    top     : 0;
    left    : 0;
    overflow: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.timetable-scroll {
    display       : flex;
    flex-direction: row;
    align-items   : stretch;

    position          : absolute;
    top               : 0;
    left              : 0;
    padding-top       : $lh* 2;
    width             : 100%;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width   : none;
    /* Firefox */
}


$shift: 12px;

.timetable-border-left {
    width     : 5%;
    // float: left;
    border-top: 1px solid var(--pc);

    background: repeating-linear-gradient(0deg,
            white 0px + $shift,
            white 40px + $shift,
            var(--pc) 40px + $shift,
            var(--pc) 41px + $shift,
            white 41px + $shift);
}

.timetable-border-right {
    width     : 5%;
    // height: 100%;
    // float: left;
    border-top: 1px solid var(--tc);

    background: repeating-linear-gradient(0deg,
            white 0px + $shift,
            white 40px + $shift,
            var(--tc) 40px + $shift,
            var(--tc) 41px + $shift,
            white 41px + $shift);
}

.timetable-columns {
    width       : 90%;
    margin      : 0;
    // height: 100%;
    // float: left;
    // display: flex;
    border-left : 1px solid var(--pc);
    border-right: 1px solid var(--tc);

    background: repeating-linear-gradient(0deg,
            white 0px + $shift,
            white 40px + $shift,
            var(--pc) 40px + $shift,
            var(--pc) 41px + $shift,
            white 41px + $shift);
}

// ---- Column

.timetable-column {
    width        : 25%;
    float        : left;
    margin-bottom: $lh*6;
}

.timetable-category {
    height        : $lh*2;
    background    : var(--white);
    // font-size: var(--small2);
    font-size     : 0.8rem;
    text-align    : center;
    text-transform: uppercase;
    padding       : $lh/2;
    font-family   : "GINTON", 'GINTO', "Helvetica Neue", arial, sans-serif;
}

.timetable-category-red2 {
    color     : var(--pc);
    border-top: 1px var(--pc) solid;
}

.timetable-category-red2 {
    color      : var(--pc);
    border-left: 1px var(--pc) solid;
    border-top : 1px var(--pc) solid;
}

.timetable-category-blue {
    color      : var(--tc);
    border-left: 1px var(--tc) solid;
    border-top : 1px var(--tc) solid;
}

.timetable-text {
    cursor       : pointer;
    text-align   : center;
    word-break   : break-word;
    color        : white;
    padding      : $lh/2;
    // font-size: var(--small2);
    font-size    : 0.8rem;
    text-align   : left;
    height       : $lh * 8;
    overflow     : hidden;
    text-overflow: ellipsis;

}

.timetable-text:hover {
    opacity: 1;
    filter : brightness(1.3);
}

.timetable-text-nohover {
    // text-align: center;
    // word-break: break-word; 
    // color: white;
    // padding: $lh/2;
    // font-size: var(--small2);
    cursor       : pointer;
    text-align   : center;
    word-break   : break-word;
    color        : white;
    padding      : $lh/2;
    // font-size: var(--small2);
    font-size    : 0.6rem;
    text-align   : left;
    height       : $lh * 10;
    overflow     : hidden;
    text-overflow: ellipsis;
}

.timetable-link {
    color          : white;
    text-decoration: none;
    position: relative;
}


.timetable-text-date {
    font-family  : 'GINTONB', "GINTOB", "Helvetica Neue", arial, sans-serif;
    margin-bottom: $lh/5;
}

.timetable-text-date-live{
    padding-right: $lh*1.5;
}

.timetable-text-artist {
    font-family: "GINTON", "GINTO", "Helvetica Neue", arial, sans-serif;
}

.timetable-text-red {
    background   : var(--pc);
    border-bottom: 1px white solid;
}

.timetable-text-red2 {
    background   : var(--pc);
    border-left  : 1px white solid;
    border-bottom: 1px white solid;
}

.timetable-text-blue {
    border-left  : 1px white solid;
    border-bottom: 1px white solid;
    background   : var(--tc);
}

// --- Close

.timetable-close {
    cursor        : pointer;
    display       : inline-block;
    padding       : $lh/2.5;
    font-family   : "GINTON", "Helvetica Neue", arial, sans-serif;
    text-transform: uppercase;
    text-align    : center;
    width         : 64px;
}

//---loading

.timetable-loading-wrapper {
    position : fixed;
    width    : 100%;
    top      : 50%;
    transform: translateY(-50%);
}

.timetable-loading-text {
    text-align : center;
    font-size  : var(--medium);
    font-family: "GINTON", "GINTO", "Helvetica Neue", arial, sans-serif;
    color      : var(--pc);
}

.timetable-loader {
    margin-left  : auto;
    margin-right : auto;
    margin-bottom: $lh/2;
    display      : block;

    border       : 2px solid rgba(0, 0, 0, 0);
    /* Light grey */
    border-top   : 2px solid var(--pc);
    /* Blue */
    border-radius: 50%;
    width        : $lh * 2;
    height       : $lh * 2;
    animation    : spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.timetable-live{
    position: absolute;
    top: 0;
    right: 0;
    width: $lh*0.9;
    height: $lh*0.9;
    border-radius: 50%;
    background: rgb(255, 230, 230);
    // border: 2px white solid;
    animation: liveanimation 3s infinite;

}


@keyframes liveanimation{

    0% {
        opacity: 0;
    }

    4% {
        opacity: 1;
    }
    70%{
        opacity: 0;
    }
    100%{
        opacity: 0;
    }
}

.timetable-link-live{
    filter : brightness(1.2);
}

.timetable-link-live:hover{
    filter : brightness(1.4);
}