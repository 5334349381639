@import "../../style/variables.scss";

.welcome-container{
     position:fixed;
     width: 60%;
     max-width: 70vh;
     height: auto;
     padding-bottom: 10vh;
     left: 50%;
     top: 50%;
     transform: translate(-50%, -50%);

     background: var(--white);
     border-radius: var(--radius2);
     position: absolute;

     filter: var(--shadow);
     z-index: 2000;
     color: var(--pc);
     @media only screen and (max-width: $secondbrekpoint) {
          width : 85%;
      }
      font-family: "GINTON", "GINTO", "Helvetica Neue", arial, sans-serif;
}

.welcome-content{
     // position: fixed;
     // top: 30%;
     // left: 50%;
     // transform: translate(-50%, -50%);
}

.welcome-headline{
     font-size: var(--big2);
     // text-transform: uppercase;
     padding: 2vh;
}

.welcome-continue{
     position: absolute;
     left: 50%;
     bottom: 5%;
     transform: translateX(-50%);
     cursor: pointer;
     font-size: var(--big);
     color: var(--pc);
     text-align: center;
     font-family: "GINTON", "GINTO", "Helvetica Neue", arial, sans-serif;
     text-transform: uppercase;
     text-decoration: none;
     background: none;
     border: none;
}

.welcome-continue:hover{
     color: var(--lightgray);
}

.welcome-text{
     font-size: var(--normal);
     padding: 2vh;
}

.welcome-text-newsletter{
     font-size: var(--normal);
     padding: 2vh;
     padding-top: 4vh;
     padding-bottom: 0;
}

.welcome-text-newsletter2{
     font-size: var(--normal);
     padding-left: 2vh;
     padding-top: 2vh;
}


.welcome-input{
     font-size: var(--medium);
     background-color: var(--lightgray);
     border-radius: 0;
     // border: 2px var(--grayalpha) solid;
     border: none;
     padding: $lh/4;
     width: 30vh;
     margin: 2vh;
     margin-left: 3vh;
     margin-bottom: 0;
     @media only screen and (max-width: $secondbrekpoint) {
          width: 25vh;
      }
}


 .welcome-faq{
      color: var(--pc);
      text-decoration: underline;
      cursor: pointer;
 }

 .welcome-faq:hover{
     text-decoration: none;
 }

 .welcome-warning{
      height: 2vh;
      width: 100%;
      color: var(--tc);
      margin-top: 1vh;
      padding-left: 5.5vh;
      font-size: var(--small);
 }

 .mailchimp-message {
      padding-left: $lh;
 }