$pc: white;
$sc: #ff8900;
$tc: #4152f5;

@mixin shadow($blur, $opacity){
    filter: drop-shadow(0px 0px $blur rgba(0,0,0,$opacity));
}

$radius: 0.5em;
$lh: 1.4em;
$big: 1.55rem;
$medium: 1.25rem;

$g1: linear-gradient(90deg, var(--sc) 0%, black 35%, var(--sc) 100%);

$firsbrekpoint: 870px;
$secondbrekpoint: 600px;
$thirdbrekpoint: 420px;