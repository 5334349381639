@import "../../style/variables.scss";

.button-close {
    cursor: pointer;
    z-index: var(--ztop);
    position: absolute;
    top: 0;
    background-color: none;
    margin: $lh / 2;
    margin-top: $lh / 2.5;
    height: $lh * 1.5;
    width: $lh * 1.5;
}

.button-close:hover {
    opacity: 0.7;
}

.button-left {
    left: 0;
}

.button-right {
    right: 0;
}

.button-right-chat{
    right: $lh * 14;
}

.button-close-section{
    cursor: pointer;
    z-index: var(--ztop);
}
.button-close-section:hover {
    opacity: 0.7;
}

.button-close-secret{
    cursor: pointer;
    z-index: var(--ztop);
    position: fixed;
    top: 0;
    background-color: none;
    margin: $lh / 2;
    margin-top: $lh / 2.5;
    height: $lh * 1.5;
    width: $lh * 1.5;
    @media only screen and (max-width: $secondbrekpoint) {
        position: absolute;
        top: $lh*3.5;
        right: $lh;

    }
}

.button-close-secret:hover {
    opacity: 0.7;
}

.button-close-portals{
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    height: $lh * 1.5;
    width: $lh * 1.5;
    font-size: var(--normal);
}

// ---------------------------------------------------
// Basic button
//

.button-basic {
    cursor: pointer;
    user-select: none;
    display: inline-block;
    margin-right: $lh / 4;
    margin-left: $lh / 4;
    color: var(--white);
    text-decoration: none;
}

.button-basic:hover {
    color: var(--lightgray);
}


// Types of basic buttons

.button-player {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
}

.button-upload {
    cursor: pointer;

    color: var(--white);
    text-decoration: none;

    position: absolute;
    top: 0;
    left: 0;
    width: $lh * 5;
    height: $lh * 5;

    background: rgba(0,0,0,0.6);
    border-radius: 100%;
 

    padding-top: 40%;
    margin: 0;
    width: 100%;
    text-align: center;
}

.button-profile {
    margin-right: $lh;
}

// ---------------------------------------------------
// Toggle
//

.button-toggle {
    font-size: var(--medium);
    font-family: "GINTON", "GINTO", "Helvetica Neue", arial, sans-serif;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;

    display: inline-block;

    user-select: none;
    cursor: pointer;
    // padding: $lh / 4;
    background-color: none;
    color: white;

    margin-right: $lh/6;
    margin-left: $lh/4;
}

.button-toggle:hover{
    color: var(--lightgray);
}


.button-toggle-avatar {
    float: left;

}

.button-toggle-chat {
    float: right;
    @media only screen and (max-width: $firsbrekpoint) {
        float: left;
    }
}

.button-switch {
    display: inline-block;
    height: 18px;
    width: calc(2em - 6px);
    margin-right: $lh / 6;
    @media only screen and (max-width: $firsbrekpoint) {
        margin-right: $lh / 2;
    }
}

// Types of toggle

// ---------------------------------------------------
// Arrows
//

.button-arrow {
    cursor: pointer;
    font-size: var(--medium);
    text-align: center;
    color: var(--dark);
    width: 2em;
    height: 2em;
    padding-top: 50px;
    // border-radius: var(--radius3);

    vertical-align: middle;
    transition: 500ms all;

    @media only screen and (max-width: $firsbrekpoint) {
        width: 1em;
        padding-top: 30px;
    }
}

.button-arrow-right {
    float: right;
}

.button-arrow-left {
    float: left;
}

.button-arrow:hover {
    opacity: 0.4;
}

// ---------------------------------------------------
// Section Arrows
//

.button-section-arrow {
    cursor: pointer;
    font-size: $lh*2;
    position: fixed;
    color: white;
    top: 50%;
    z-index: var(--zslider4);
    float: left;

    @media only screen and (max-width: $firsbrekpoint) {
        animation-name: none;
    }
}

.button-section-arrow:hover {
    opacity: 0.5;
}

.button-section-arrow:active {
    color: var(--tc);
}

.button-section-arrow-left {
    animation-name: arrow-animation-left;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    left: $lh/2;
    @media only screen and (max-width: $firsbrekpoint) {
        left: $lh/5;
    }
}

.button-section-arrow-right {
    animation-name: arrow-animation-right;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    right: $lh/2;
    @media only screen and (max-width: $firsbrekpoint) {
        right: $lh/5;
    }
}

.button-section-arrow-right-c {
    animation-name: arrow-animation-right;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    right: $lh * 5;
    @media only screen and (max-width: $firsbrekpoint) {
        right: $lh * 3.5;
    }
    @media only screen and (max-width: $secondbrekpoint) {
        right: $lh/5;
    }
}

@keyframes arrow-animation-right {
    40% {
        transform: translateX(0%);
    }
    50% {
        transform: translateX(-50%);
    }
    60% {
        transform: translateX(0%);
    }
}

@keyframes arrow-animation-left {
    40% {
        transform: translateX(0%);
    }
    50% {
        transform: translateX(50%);
    }
    60% {
        transform: translateX(0%);
    }
}

// ---------------------------------------------------
// ---------------------------------------------------
// ---------------------------------------------------
// NEW
//

.button-square {
    cursor: pointer;

    font-size: var(--medium);
    font-family: "GINTON", "GINTO", "Helvetica Neue", arial, sans-serif;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;

    padding: 0;
    padding-left: $lh / 10;
    padding-right: $lh / 10;
    margin: $lh / 3;
    
}


.button-white {
    background: white;
    color: var(--pc);
}

.button-white:hover {
    background-color: var(--lightgray);
    border-color: var(--lightgray);
}

.button-white:active{
    color: var(--tc);
}

.button-gray {
    background-color: var(--grayalpha);
    color: white;
}

.button-gray:hover {
    color: var(--lightgray);
    border-color: var(--lightgray);
}

.button-darkgray {
    background: var(--grayalpha);
    color: var(--dark);
}

// Types of WHITE button

.button-timetable {
    z-index: var(--zsectionwindow2);
    position: fixed;
    width: auto;
    top: $lh / 4;
    left: $lh * 2;
    @media only screen and (max-width: $thirdbrekpoint) {
        top: $lh*1.75;
    }
}

.button-about {
    z-index: var(--zlanding2);
    position: fixed;
    width: auto;
    top: $lh / 4;
    left: $lh / 4;
}

.button-donate {
    z-index: var(--zsectionwindow2);
    position: fixed;
    bottom: $lh * 1.75;
    left: $lh / 4;
    @media only screen and (max-width: $firsbrekpoint) {
        bottom: $lh * 3.3;
    }
}

.button-donate-inwindow {
    z-index: var(--zsectionwindow2);
    position: fixed;
    bottom: $lh * 1.75;
    left: $lh / 4;
    @media only screen and (max-width: $firsbrekpoint) {
        bottom: $lh * 1.75;
    }
}

.button-mute {
    z-index: var(--zsectionwindow2);
    position: fixed;
    bottom: $lh / 4;
    left: $lh / 4;

    @media only screen and (max-width: $firsbrekpoint) {
        bottom: $lh * 1.75;
    }
}

.button-mute-inwindow {
    z-index: var(--zsectionwindow2);
    position: fixed;
    bottom: $lh / 4;
    left: $lh / 4;

    @media only screen and (max-width: $firsbrekpoint) {
        bottom: $lh / 4;
    }
}

.button-section {
    z-index: var(--zslider4);
    position: absolute;
    left: 50%;
    top: 70%;
    transform: translate(-50%, -50%);
}

.button-enter {
    position: absolute;
    left: 50%;
    bottom: $lh * 2.5;
    transform: translateX(-50%);
    width: 90%;

    display: block;
    margin-left: auto;
    margin-right: auto;
    font-size: var(--medium2);
    border: none;
}

.button-enter:hover {
    background-color: var(--white);
    border-color: var(--white);
    color: var(--lightgray);
}

.button-secret {
    position: absolute;
    border-radius: 0;
    font-size: var(--medium);
    margin: 0;
    height: 2em;
}

.button-clifford{
    font-size: var(--normal) !important;
    margin-bottom: 0;
    width: 8em;
    height: 6em;
    vertical-align: 0%;
}

.button-clap-container{
    cursor: pointer;
    font-size: var(--medium);
    background: var(--white);
    border-radius: 100%;

    padding-top: $lh/1.5;
    margin: $lh / 3;
    width: $lh*2.5;
    height: $lh*2.5;

    z-index: var(--zsectionwindow2);
    position:fixed;
    bottom: $lh / 4;
    left: 50%;
    transform: translateX(-50%);
    @include shadow(8px, 0.3)
}

.button-clap-container:hover{
    background: var(--lightgray);
}

.button-clap {
    width: $lh*1.2;
    height: $lh*1.2;
    margin-left: auto;
    margin-right:auto;
    display:block;
    background-image: url('../../public/images/svg/heart1.svg/');
    background-repeat: no-repeat;
}

// Types of GRAY button

.button-position-packed {
    font-size: var(--medium);
    position: fixed;
    text-align: left;
    top: $lh / 4;
    left: $lh * 7;
    z-index: var(--zsectionwindow1);
    width: auto;
    padding-left: $lh / 4;
    padding-right: $lh / 4;
    @media only screen and (max-width: 1250px) {
        top: $lh * 1.75;
        left: $lh / 4;
    }
    @media only screen and (max-width: $thirdbrekpoint) {
        top: $lh*3.3;
    }

}

.button-position-unpacked-container{
    user-select: none;
    font-size: var(--medium);
    position: fixed;
    top: $lh / 4;
    left: $lh * 7;
    z-index: var(--zsectionwindow1);
    width: auto;
    margin: $lh / 3;
    background: var(--grayalpha);
    padding: 0;
    @media only screen and (max-width: 1250px) {
        top: $lh * 1.75;
        left: $lh / 4;
    }
    @media only screen and (max-width: $thirdbrekpoint) {
        top: $lh*3.3;
    }
}

.button-position-unpacked{
    text-align: left;
    width: 100%;
    display: block;
    background: none;
    color: white;
    margin: 0;
    border: 2px white solid;
    border-bottom: none;
    padding-left: $lh / 4;
    padding-right: $lh / 4;
}

.button-position-unpacked:hover{
    color: var(--lightgray);
}

.button-position-unpacked:active{
    color: var(--tc);
}

.button-position-highlighted{
    background-color:white; 
    color: #FF5B4A;
}

.button-position-highlighted:hover{
    color: #FF5B4A;
}

.button-position-highlighted:active{
    color: var(--tc);
}

.button-save {
    display: block;
    margin-bottom: $lh / 2;
    margin-top: $lh / 2;
    margin-left: auto;
    margin-right: auto;
    width: 5em;
}

.inactive {
    opacity: 0.3;
    cursor: default;
}

// Other buttons

.button-overview {
    border-radius: 100%;
    z-index: var(--zsectionwindow2);
    position: fixed;
    width: 36px;
    height: 36px;
    top: $lh / 4;
    left: $lh / 2;
    @media only screen and (max-width: $firsbrekpoint) {
        width: 30px;
        height: 30px;
    }
    @media only screen and (max-width: $thirdbrekpoint) {
        top: $lh*1.75;
    }
}

.button-overview:hover {
    background-color: var(--lightgray);
}

// --------------
// Check boxes

.button-checkbox {
    position: relative;
    top: $lh/3;
    right: $lh/6;
    height: $lh;
    width: $lh;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    border-radius: 0;
    outline: none;
    transition-duration: 0.3s;
    cursor: pointer;
    margin-right: $lh/2;
  }

.button-checkbox-welcome:checked {
    background-color: var(--pc);
}

.button-checkbox-donate:checked{
    background-color: var(--white);
}