.installationOverlay {
    position: fixed;
    background: green;
    z-index: 500;
    bottom: $lh * 3;
    left: $lh / 2;
    // transform: translate(-50%,0);
    width: 60%;
    pointer-events: none;
    padding: $lh / 2;
    border-radius: $radius;
    background: rgba(0,0,0, 0.5);
    backdrop-filter: blur(0.5em);
    line-height: 1.36;
}