@import "../style/variables.scss";

.profile-container {
    margin-top: $lh*2;
    padding: $lh / 2;
    width: 20em;
    height: 22em;
    background: var(--white);
    border-radius: var(--radius2);;
    position: fixed;
    left: 50%;
    top: $lh * 4;
    transform: translate(-50%,0);
    filter: var(--shadow);
    color: var(--dark);
    z-index: 1000;
}

.profile-image-container {
    margin-top: $lh;
}

.profile-avatar {
    border-radius: var(--radius);
    width: 2em;
}

.profile-progress {
    margin-bottom: $lh;
}

.profile-loader-container{
    margin-top: $lh*2;
    padding: $lh / 2;
    width: 20em;
    height: 22em;
    border-radius: var(--radius2);;
    position: fixed;
    left: 50%;
    top: $lh * 4;
    transform: translate(-50%,0);
    filter: var(--shadow);
    color: var(--dark);
    z-index: 1000;
    background: rgba(255,255,255,0.8);
}

.profile-loader {
    position: absolute;
    top: 40%;
    left: 10em - $lh * 1.5;

    border: 2px solid rgba(0,0,0,0); /* Light grey */
    border-top: 2px solid var(--dark); /* Blue */
    border-radius: 50%;
    width: $lh * 3;
    height: $lh * 3;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg) }
    100% { transform: rotate(360deg)}
  }

  .profile-shortname{
      text-align: center;
    color: var(--pc);
    font-size: var(--small);
    height: $lh;
    margin-bottom: $lh*1.5;
}