@import "../style/variables.scss";

/* 
Logged container 
    includes Header and Footer components
*/

.logged-container {
    position: relative;
}


/* 
Header container 
    includes ??
*/

.header {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translate(-50%,0);
    color: $pc;
    padding: $lh / 4;
    padding-top: $lh / 8;
    z-index: 100;
    user-select: none;
    color: $pc;
    width: 70vw;

    @media only screen and (max-width: $firsbrekpoint) {
        width: 100%;
        font-size: 0.8em;
        left: 0;
        transform: translate(0,0);
        padding-left: 7.5em;;
    }
}




.flashmessage-container {
    text-align: left;

}
.flashmessage {
    padding: $lh /2;
    // background: rgba(255,255,255,0.1);
    margin-top: $lh /4;
    display: inline-block;
    border-radius: $radius;
    // filter: drop-shadow(0px 0px 4px rgba(255,255,255,0.8));
    // box-shadow: inset 0px 0px 10px rgba(255,255,255,0.5);
}


/* 
Footer container 
*/

.footer-control-panel {
    font-size: var(--medium);
    font-family: 'GINTON', 'GINTO','Helvetica Neue', arial, sans-serif;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;

    margin: $lh / 3;

    z-index: var(--zsectionwindow2);

    position: fixed;
    bottom: $lh / 4;

    background: var(--grayalpha);
    color: white;
}

.footer-left {
    z-index: var(--zslider4);
    padding: 0;
    padding-left: $lh / 4;
    padding-right: $lh / 10;

    @media only screen and (max-width: $firsbrekpoint) {
        bottom: $lh / 4;
        left: $lh / 4;
    }
}

.footer-muted{
    left: $lh * 4.4;
    @media only screen and (max-width: $firsbrekpoint) {
        left: $lh / 4;
    }
}

.footer-unmuted{
    left: $lh * 3.2;
    @media only screen and (max-width: $firsbrekpoint) {
        left: $lh / 4;
    }
}   

.footer-right {
    width: $lh * 10;
    right: $lh / 4;
    padding: 0;

    @media only screen and (max-width: $firsbrekpoint) {
        width: $lh * 7;
    }
    @media only screen and (max-width: $secondbrekpoint) {
        display: none;
    }
}


.footer-profile {
    display: inline-block;
    img {
        width: 1em;
        height: 1em;
        border-radius: 5em;
        vertical-align: middle;
    }
}

