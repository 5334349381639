:root {
    --lh: 1.38rem;
    --pc: rgb(255, 91, 74); 
    --sc: #ec6652;
    --tc: #4254f5;

    --darkblue: rgb(4, 8, 41);
    --brightblue: rgb(242, 244, 255);
    

    --warning: #ee4c30;
    --white: white;
    --dark: black;
    --darkhalf: rgba(0,0,0,0.3);
    --lightgray: rgb(230, 230, 230);
    --mediumgray: rgb(183, 183, 183);
    --darkgray: rgb(103, 103, 103);
    --grayalpha: rgba(153, 153, 153, 0.4);

    --shadow: drop-shadow(0px 0px 7px rgba(0,0,0,0.25));
    
    --background: #E1DFC9;
    --navcolor: #8000ff;

    --color2: #00ff00;
    --color: black;
    
    --radius: 0.25em;
    --radius2: 0.5em;
    --radius3: 1em;

    --huge: 3rem;
    --big3: 2.5rem;
    --big2: 2rem;
    --big: 1.55rem;
    --medium2: 1.4rem;
    --medium: 1.25rem;
    --normal2: 1.15rem;
    --normal: 1rem;
    --small2: 0.9rem;
    --small: 0.8rem;
    --mini2: 0.7rem;
    --mini: 0.6rem;


    --zbottom: 1;

    --zslider: 100;
    --zslider1: 110;
    --zslider2: 120;
    --zslider3: 130;
    --zslider4: 140;

    --zsectionwindow: 400;
    --zsectionwindow1: 410;
    --zsectionwindow2: 420;
    --zsectionwindow3: 430;

    --zlanding: 800;
    --zlanding1: 810;
    --zlanding2: 820; 

    --ztop: 1000;

}