@import "../../style/variables.scss";

.chat {
    position: fixed;
    font-size: var(--medium);
    top: $lh / 4;
    right: $lh / 4;
    bottom: $lh * 1.75;
    width: $lh * 10;

    margin: $lh / 3;
    // padding: $lh / 4;
    padding-top: 0;
    z-index: var(--zsectionwindow3);

    // pointer-events: none;
    background-color: var(--grayalpha);
    @media only screen and (max-width: $firsbrekpoint) {
        bottom: $lh * 2.8;
        width: $lh * 7;
    }
    @media only screen and (max-width: $secondbrekpoint) {
        display: none;
    }
}

/*
    chat-messages
     L  chat-image (avatars)
     L  chat-content
         L  chat-name
         L  chat-content-message
         L  chat-content-mymessage
*/

.chat-messages {
    height: calc(100% - 2.5em);
    width: 100%;
    overflow-y: auto;
    word-break: break-all;
}

.chat-image {
    width: $lh * 1.7;
    height: $lh * 1.7;
    left: 10px;
    margin-left: 7px;
    margin-top: 2px;
    float: left;
    // display: inline-block;
    border-radius: 100%;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
    }
}

.chat-content {
    width: auto;
    height: 100%;
    background: var(--brightblue);
    padding: $lh / 4;
    
    margin-bottom: $lh / 4;
    margin-left: 3.5em;
    color: var(--dark);
}

.chat-name {
    font-size: var(--mini2);
    margin-bottom: 0.25rem;
}

.chat-content-message {
    user-select: text !important;
    margin-top: 0.1em;
    font-size: var(--small2);

    a {
        color: gray;
    }
}

.chat-content-mymessage {
    background: var(--tc);
    color: var(--white);

    a {
        color: var(--lightgray);
    }
}

/*
Chat Bar
    includes chat-bar, chat-input
    chat-image class is same for Bar and Messages
*/

.chat-bar {
    position: absolute;
    font-size: var(--medium);
    bottom: $lh / 4;
    right: $lh / 4;
    left: $lh / 4;
    width: auto;
    height: $lh;
}

.chat-input-field{
    float: left;
    font-size: var(--medium);
    width: $lh * 8;
    height: $lh;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 0;
    @media only screen and (max-width: $firsbrekpoint) {
        width: $lh * 4;
    }
}

.chat-input-submit{
    font-size: var(--medium);
    cursor: pointer;
    float: right;
    width: $lh;
    height: $lh;
    border-radius: 100%;
    background:var(--tc);

    color: white;
}


.chat-bar-image{
    width: $lh * 1.8;
    height: $lh * 1.8;
}

