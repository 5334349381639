@import "../../style/variables.scss";



.clap-heart {
    position          : fixed;
    font-size         : var(--big);
    z-index           : var(--zsectionwindow2);
    bottom            : 0;
    animation-name    : yposition;
    animation-duration: 5s;
    animation-timing-function: linear;

    width                      : 1em;
    height                     : 1em;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

@keyframes yposition {
    0% {
        transform: translateY(0)
    }

    100% {
        transform: translateY(-110vh);
        display: none;
    }



}

.clap-heart1 {
    width           : $lh;
    height          : $lh;
    background-image: url('../../public/images/svg/heart1.svg/');
    background-repeat: no-repeat;
    @include shadow(6px, 0.3)
}

.clap-heart2 {
    width           : $lh;
    height          : $lh;
    background-image: url('../../public/images/svg/heart2.svg/');
    background-repeat: no-repeat;
    @include shadow(6px, 0.3)
}

.clap-heart3 {
    width           : $lh;
    height          : $lh;
    background-image: url('../../public/images/svg/heart3.svg/');
    background-repeat: no-repeat;
    @include shadow(6px, 0.8)
}

.clap-heart4 {
    width           : $lh;
    height          : $lh;
    background-image: url('../../public/images/svg/heart4.svg/');
    background-repeat: no-repeat;
    @include shadow(6px, 0.8)
}

.clap-carhartt {
    width           : $lh;
    height          : $lh;
    background-image: url('../../public/images/svg/carhartt.svg/');
    background-repeat: no-repeat;
    @include shadow(6px, 0.1)
}

